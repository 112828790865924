
const depthLevel = document.getElementById('depthlevel');
const bottomElement = document.querySelector('.bottom-soil');

const totalDepth = 11022;

const ratio = (document.body.scrollHeight - window.innerHeight - bottomElement.clientHeight) / totalDepth;

let currentDepth;


document.addEventListener("scroll", function () {

    currentDepth = Math.round(window.scrollY / ratio);

    if (currentDepth < 0) currentDepth = 0;

    depthLevel.textContent = `${currentDepth} Metre Derinliktesin`;



    if (currentDepth > totalDepth) {
        depthLevel.style.position = 'absolute';
        depthLevel.style.bottom = '1px';
        depthLevel.textContent = `${totalDepth} Metre Derinliktesin`;
    } else if (depthLevel.style.position == 'absolute') {
        depthLevel.style.position = 'fixed';
        depthLevel.style.bottom = '5%';
    }


});

let isPlayed = false;
const audio = new Audio('https://www.mp3indirdur.info/mp3/indirdurArsiv333/Cakal/Mahvettim/Cakal-Imdat.mp3');

function playAudio(url) {

    if (!isPlayed)
        audio.play();
    else
        audio.pause();

    isPlayed = !isPlayed;
}

